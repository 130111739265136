var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c(_vm.component, {
        tag: "component",
        attrs: {
          "payment-type": _vm.paymentType,
          "order-id": _vm.orderId,
          mode: _vm.mode,
          options: _vm.options,
          smartCart: _vm.smartCart
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }